/* General Styles */

body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
}

/* Quiz Card */

.slide-container { width:  }

.question-card {
  margin: 50px auto;
  width: 80%;
  max-width: 600px;
  height: auto;
  padding: 20px;
  border-radius: 20px;
  color: #333; /* Darker text for better readability */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1), 0px 8px 24px rgba(0, 0, 0, 0.1);
}

ul {
  list-style: none;
  padding: 0;
}

li {
  margin-top: 12px;
  background-color: #ddd; /* Lighter background for better contrast */
  padding: 12px;
  border: 2px solid #ccc; /* Softer border color */
  border-radius: 15px;
  font-size: 16px; /* Smaller font size */
}

li:hover {
  background: linear-gradient(#f71aa2, #ff961d);
}

.question-text {
  color: #1a73e8;
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 16px;
}

/* Final Results */

.final-results {
  margin: 50px auto;
  width: 60%;
  max-width: 600px;
  height: auto;
  padding: 20px;
  border-radius: 20px;
  color: #333; /* Darker text for better readability */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1), 0px 8px 24px rgba(0, 0, 0, 0.1);
}

/* Button Styles */
#submitButton {
  width: 60%; /* Smaller button width */
  font-size: 14px; /* Smaller font size */
  color: #ffffff;
  background: linear-gradient(#ff961d, #f71aa2);
  border-radius: 15px;
  padding: 10px; /* Smaller padding */
  border: 2px solid #234668;
  cursor: pointer;
  transition: background 0.3s ease;
}

#submitButton:hover {
  background: linear-gradient(#f71aa2, #ff961d);
}
